import { Button } from '@mui/material';

import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export function MyButton({ handleClick, ButtonText }) {

  return (
    <Button variant="contained" onClick={handleClick}>
      {ButtonText}
    </Button>
  );
}

export function DenseTable({ sensor }) {

  console.log(sensor.measurements);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Analog</TableCell>
            <TableCell align="right">Data Point Name</TableCell>
            <TableCell align="right">Multi State</TableCell>
            <TableCell align="right"># of Decimals</TableCell>
            <TableCell align="right">Units</TableCell>
            <TableCell align="right">Alarm Enabled</TableCell>
            <TableCell align="right">Low Alarm Limit</TableCell>
            <TableCell align="right">High Alarm Limit</TableCell>
            <TableCell align="right">Priority Classification</TableCell>
            <TableCell align="right">Featured</TableCell>
          </TableRow>
        </TableHead>
        {/* <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}

        <TableBody>

        </TableBody>
      </Table>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Digital</TableCell>
            <TableCell align="right">Data Point Name</TableCell>
            <TableCell align="right">Alarm Enabled</TableCell>
            <TableCell align="right">On Value</TableCell>
            <TableCell align="right">Off Value</TableCell>
            <TableCell align="right">Priority Classification</TableCell>
            <TableCell align="right">Featured</TableCell>
          </TableRow>
        </TableHead>
        {/* <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}

        <TableBody>

        </TableBody>
      </Table>
    </TableContainer>
  );
}