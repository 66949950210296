function IOMapAnalog( ServerName, SetValues, Decimals , Units, Alarm, AlarmMin, AlarmMax, PriorityLeel, Featured) {
  return { ServerName, SetValues, Decimals , Units, Alarm, AlarmMin, AlarmMax, PriorityLeel, Featured };
}

function IOMapDigital( ServerName, Alarm, OnText, OffText, PriorityLeel, Featured) {
  return { ServerName, Alarm, OnText, OffText, PriorityLeel, Featured };
}

const Keyence_FDQ =
{
  id: 1,
  type: 'Flow Meter',
  name: 'FD-Q',
  description: 'Clamp on flow meter',
  imageURL: 'https://www.keyence.com/Images/series_fd-q_keyvisual_1968635.jpg',
  measurements: {
    FlowTotal: {
      MeasurementComment: "Flow Total",
      RegType: "ISDU",
      StartReg: ",149",
      TotalPoints: 1,
      PLCFormat: "INT",
      ServerType: "ANALOG",
      Scale: "DivideBy,1/10",
      IOConfigAnalog: [
        IOMapAnalog("FD-Q Flow Total", 0, 2, 'Gal', 0, 0, 100, "Information", 1),
      ],
      IOConfigDigital:[],
    },
    FlowRate: {
      MeasurementComment: "Flow Rate",
      RegType: "PD",
      StartReg: ",1",
      TotalPoints: 1,
      PLCFormat: "INT",
      ServerType: "ANALOG",
      Scale: "DivideBy,10",
      IOConfigAnalog: [
        IOMapAnalog("FD-Q Flow Rate", 0, 2 , 'GPM', 0, 0, 100, "Information", 1),
      ],
      IOConfigDigital:[],
    },
    Stability: {
      MeasurementComment: "Stability",
      RegType: "ISDU",
      StartReg: ",180",
      TotalPoints: 1,
      PLCFormat: "INT",
      ServerType: "ANALOG",
      Scale: "DivideBy,256",
      IOConfigAnalog: [
        IOMapAnalog("FD-q Stability", 0, 2 , '', 0, 0, 100, "Information", 1),
      ],
      IOConfigDigital:[],
    }
  }
}

const IFM_PN2293 =
{
  id: 2,
  type: 'Pressure',
  name: 'PN2293',
  description: 'Pressure sensor',
  imageURL: 'https://media.ifm.com/CIP/mediadelivery/rendition/e6b1f12ba5687b4b8df351986c9c3bfb/-B600-FJPG/PN2293',
  measurements: {
    Pressure: {
      MeasurementComment: "Pressure",
      RegType: "PD",
      StartReg: ",1",
      TotalPoints: 1,
      PLCFormat: "INT",
      ServerType: "ANALOG",
      Scale: "DivideBy,68.94744",
      IOConfigAnalog: [
        IOMapAnalog('PN2293 Pressure', 0, 2, 'PSIG', 0, 0, 100, "Information", 1),
      ],
      IOConfigDigital:[],
    }
  }
}

const IFM_AL2201 =
{
  id: 2,
  type: 'ADInputs',
  name: 'AL2201',
  description: 'Digital and Analog input block',
  imageURL: 'https://media.ifm.com/CIP/mediadelivery/rendition/d4205180c1004b44a7297cecc1bb526d/-B1500-FJPG/AL2201',
  measurements: {
    AnalogInputs: {
      MeasurementComment: "Analog Inputs",
      RegType: "PD",
      StartReg: ",4",
      TotalPoints: 8,
      PLCFormat: "INT",
      ServerType: "ANALOG",
      Scale: "Linear Scaling,4000,20000,",
      IOConfigAnalog: [
        IOMapAnalog('Port 1', 0, 2, 'mA', 0, 0, 100, "Information", 1),
        IOMapAnalog('Port 2', 0, 2, 'mA', 0, 0, 100, "Information", 1),
        IOMapAnalog('Port 3', 0, 2, 'mA', 0, 0, 100, "Information", 1),
        IOMapAnalog('Port 4', 0, 2, 'mA', 0, 0, 100, "Information", 1),
        IOMapAnalog('Port 5', 0, 2, 'mA', 0, 0, 100, "Information", 1),
        IOMapAnalog('Port 6', 0, 2, 'mA', 0, 0, 100, "Information", 1),
        IOMapAnalog('Port 7', 0, 2, 'mA', 0, 0, 100, "Information", 1),
        IOMapAnalog('Port 8', 0, 2, 'mA', 0, 0, 100, "Information", 1),
      ],
      IOConfigDigital:[],
    },
    DigitalInputs: {
      MeasurementComment: "Digital Inputs",
      RegType: "PD",
      StartReg: ",2",
      TotalPoints: 16,
      PLCFormat: "BIT",
      ServerType: "DIGITAL",
      Scale: "",
      IOConfigAnalog: [],
      IOConfigDigital:[
        IOMapDigital('Port 1', 0, 'ON', 'OFF', "Information", 1),
        IOMapDigital('Port 2', 0, 'ON', 'OFF', "Information", 1),
        IOMapDigital('Port 3', 0, 'ON', 'OFF', "Information", 1),
        IOMapDigital('Port 4', 0, 'ON', 'OFF', "Information", 1),
        IOMapDigital('Port 5', 0, 'ON', 'OFF', "Information", 1),
        IOMapDigital('Port 6', 0, 'ON', 'OFF', "Information", 1),
        IOMapDigital('Port 7', 0, 'ON', 'OFF', "Information", 1),
        IOMapDigital('Port 8', 0, 'ON', 'OFF', "Information", 1),
      ],
    }
  }
}

export const sensorList = { 1: Keyence_FDQ, 2: IFM_PN2293, 3: IFM_AL2201 }