import { Card, CardActions, Typography, CardContent, CardMedia, Modal, Accordion, AccordionSummary, AccordionDetails, Box, Divider  } from '@mui/material/';
// import Typography from '@mui/material/Typography';
// import Stack from '@mui/material/Stack';
// import { sensorList } from './data';

import React, { useState } from "react";
import { experimentalStyled as styled } from '@mui/material';
import { Button, Paper, Grid } from '@mui/material';

import { MyButton, DenseTable } from './util';
import { IFMPortSelections } from './portSelect';
import { sensorList } from './data';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function MyApp() {

  const IFMPortProps = new Array(8).fill("").map(useFormInput);

  const headerstyle = {
    textAlign: 'center'
    };


  return (
    <div>
      <h1 style={headerstyle} >IFM to FLEX Configuration Generator</h1>
      <Divider />
      <IFMPortSelections IFMPortProps={IFMPortProps} />
      <Divider />
      <IFMPortCards IFMPortProps={IFMPortProps} />
    </div>
  );
}


export function useFormInput(initialValue) {
  const [value, setValue] = useState('');


  function handleSensorSelection(e) {
    setValue(e.target.value);
  }

  const inputProps = {
    value: value,
    onChange: handleSensorSelection
  };

  return inputProps;
}


const IFMPortCards = (IFMPortProps) => {

  const printSelections = () => {
    console.log("Button IFMPortProps:", IFMPortProps.IFMPortProps);
    console.log("");

  };

  return (
    <>
      <div style={{ margin: "10px" }}>
        {/* <Box sx={{  margin: "10px"}}> */}
        <Grid container columns={{ xs: 1, sm: 2, md: 2 }}>

          {Array.from(Array(8)).map((_, index) => (

            <Grid item xs={1} sm={1} md={1} key={index}>
              <Item>
                <MediaCard IFMport={index + 1} portProps={IFMPortProps.IFMPortProps[index]} />
              </Item>
            </Grid>
          ))}

        </Grid>
        {/* </Box> */}
      </div>
      <MyButton handleClick={printSelections} ButtonText={"Print Selection"} />
    </>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: "10px",
  textAlign: 'Left',
  color: theme.palette.text.secondary,
}));

function MediaCard({ IFMport, portProps }) {

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const FloatingBoxAccordianStyle = {
    position: 'absolute',
    top: '25%',
    left: '12.5%',
    width: '75%',
  };


  const sensorInfo = sensorList[portProps.value];
  console.log(sensorInfo);
  if (sensorInfo !== undefined) {
    return (
      <Card>
        <CardMedia
          sx={{ height: 200 }}
          image={sensorInfo.imageURL}
          // image={sensorList[portProps[IFMport-1].deviceID]}
          title={"Port " + IFMport + " Device"}
        />
        <CardContent>
          Port {IFMport}: {sensorInfo.name}

        </CardContent>
        <CardActions>
          <MyButton handleClick={handleToggle} ButtonText="test" />
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={FloatingBoxAccordianStyle}>
              {
                Object.entries(sensorInfo.measurements).map(([key, value]) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {value.MeasurementComment}
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                      [Ethernet Port - Msg #]<br/>
                      PLC Reg Type={value.RegType}<br/>
                      Starting PLC Reg Num={IFMport}{value.StartReg}<br/>
                      Total Points={value.TotalPoints}<br/>
                      PLC Point Format={value.PLCFormat}<br/>
                      Server Point Type={value.ServerType}<br/>
                      Starting Server Point Num=#<br/>
                    </Typography>
                    <DenseTable sensor={sensorInfo}></DenseTable>
                    
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </Box>
          </Modal>

        </CardActions>
      </Card>
    );
  }
  else {
    return (
      <Card>
        <CardMedia
          sx={{ height: 200 }}
          image={"none"}
          title={"Port " + IFMport + " Device"}
        />
        <CardContent>
          Port {IFMport}: None
        </CardContent>
        <CardActions>
          <MyButton />
          <Button disabled size="small">Share</Button>
          <Button disabled size="small">Learn More</Button>
        </CardActions>
      </Card>
    );
  }

}