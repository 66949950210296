import { Select, FormControl, Box, InputLabel, Tooltip, MenuItem, Avatar, Stack } from '@mui/material';
import { MyButton } from './util';
import { sensorList } from './data';
import ifmImage from "./part_pictures/AL1342.png"

export const IFMPortSelections = (IFMPortProps) => {

    const printSelections = () => {
        console.log("Button IFMPortProps:", IFMPortProps.IFMPortProps);
        console.log("");
    };

    return (
        <>
            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", margin: "10px" }}>
                <div style={{ width: "48%", textAlign: "right", marginRight: "4%" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ margin: "10px" }}>
                            <SensorSelect IFMport="1" portProps={IFMPortProps.IFMPortProps[0]} />
                        </div>
                        <div style={{ margin: "10px" }}>
                            <SensorSelect IFMport="3" portProps={IFMPortProps.IFMPortProps[2]} />
                        </div>
                        <div style={{ margin: "10px" }}>
                            <SensorSelect IFMport="5" portProps={IFMPortProps.IFMPortProps[4]} />
                        </div>
                        <div style={{ margin: "10px" }}>
                            <SensorSelect IFMport="7" portProps={IFMPortProps.IFMPortProps[6]} />
                        </div>
                    </div>
                </div>
                <div style={{ width: "20%", maxWidth: "600px" }}>
                    <img
                        src={ifmImage}
                        alt="IFM device"
                        style={{ width: "100%", height: "auto", maxHeight: "550px", maxWidth: "166px", minHeight: "300px", minWidth: "90px" }}
                    />
                </div>
                <div style={{ width: "48%", textAlign: "left", marginLeft: "4%" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ margin: "10px" }}>
                            <SensorSelect IFMport="2" portProps={IFMPortProps.IFMPortProps[1]} />
                        </div>
                        <div style={{ margin: "10px" }}>
                            <SensorSelect IFMport="4" portProps={IFMPortProps.IFMPortProps[3]} />
                        </div>
                        <div style={{ margin: "10px" }}>
                            <SensorSelect IFMport="6" portProps={IFMPortProps.IFMPortProps[5]} />
                        </div>
                        <div style={{ margin: "10px" }}>
                            <SensorSelect IFMport="8" portProps={IFMPortProps.IFMPortProps[7]} />
                        </div>
                    </div>
                </div>
            </div>

            <MyButton handleClick={printSelections} ButtonText={"Print Selection"} />
        </>
    );
};

export function SensorSelect({ IFMport, portProps }) {

    return (
        <>
            <Tooltip title={"Select what device is plugged into port " + IFMport} enterDelay={3000} leaveDelay={10}>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Port {IFMport} Sensor</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={portProps.value}
                            label="Age"
                            onChange={portProps.onChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                Object.entries(sensorList).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        <Stack direction="row" spacing={2}>
                                            <Avatar alt={value.name} src={value.imageURL} sx={{ width: 24, height: 24 }}/>
                                            <div>{value.name}</div>
                                        </Stack>
                                    </MenuItem>
                                )
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Tooltip>
        </>);
}